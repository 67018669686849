/**
 * Colors
 */

export const COLOR = {
  debug: 'rgba(0,0,0,0.1)',

  background: 'white',

  primary: '#656565',
  primaryLight: '#f2f2f2',

  white: '#FFFFFF',
  black: '#000000',
};
