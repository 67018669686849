import PropTypes from 'prop-types';
import React from 'react';

import { Flex, Box, Image, Link, Text } from 'rebass';
import logo from '../images/dotpedia-logo.png';
import registerIcon from '../images/register-seal.png';

function Header() {
  // Complex custom style
  const logoImageWidth = '120px';
  const headerStyle = {
    position: 'fixed', width: '100%', zIndex: '200', overflow: 'hidden', maxWidth: '100%',
    height: '70px',
    top: '0',
    zIndex: '4',
    margin: '0',
    WebkitBoxShadow: '0px 1px 2px 0px rgba(0,0,0,0.2)',
    MozBoxShadow: '0px 1px 2px 0px rgba(0,0,0,0.2)',
    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.2)',
    transition: 'all 0.3s ease',
    paddingLeft:'10%',
    paddingRight:'10%',
     '@media (max-width: 1024px)': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  };

  const logoStyle = {
    height: '40px',
    '@media (max-width: 1024px)': {
      height: '30px',
    },
  };


  const registerStyle = {
    fontWeight:'100',
    fontFamily: 'Lato',
    color:'#656565',
    textDecoration: 'blink'
  };

  const registerIconStyle = {
    height: '20px',
  };

  const titleStyle = {
    fontWeight: '100',
    color: '#656565',
    fontFamily: 'Lato',
    '@media (max-width: 960px)': {
      display: 'none !important',
    },
  };

  return (
    <Flex
      style={headerStyle}
      //px={[5, 6, 7]}
      py="2"
      bg="white"
      alignItems='center'>
      <Link py={4} px={2} variant='nav' href='/'>
        <Image src={logo} sx={logoStyle} alt={'logo dotpedia'} />
      </Link>
      <Text fontSize={[1,2,3]}  px={2} minWidth="auto" sx={titleStyle} > The Magnetic Dot Encyclopedia</Text>
      <Box mx="auto" overflow="hidden" />
      <Image src={registerIcon} sx={registerIconStyle} alt={'logo dotpedia'} />
      <Link fontSize={[1,2,3]} px={1} py={2} variant='nav' href='/register' sx={registerStyle}>
        Register
      </Link>
    </Flex>
  );
}

export default Header;
