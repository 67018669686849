/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { Box } from 'rebass';

import Header from './Header';
import Footer from './Footer';
import SEO from './seo';


const Layout = ({ children, padder }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // Custom object styling
  const boxStyleProps = {
    color: 'white',
    paddingTop: padder ? '0': '90px',
    maxWidth: '100%',
    overflowX: 'hidden',
    paddingLeft: padder ? '0' :'10%',
    paddingRight: padder ? '0' :'10%',
    alignSelf: 'center',
    '@media (max-width: 1024px)': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  };
  return (
    <Box>
      <SEO />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Box  {...boxStyleProps}>{children}</Box>
      <Footer />
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
