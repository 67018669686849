import React from 'react';

import { Flex, Box, Link, Text } from 'rebass';

import globe from '../images/dotpedia-globe.png';

const menuItemStyle = {
  fontWeight: '100',
  fontFamily: 'Lato',
  color: '#656565',
  textDecoration: 'blink',
};
const subMenuStyle = {
  paddingLeft: '10%',
  '@media (max-width: 1024px)': {
    paddingLeft: '0',
    paddingRight: '0',
  },
};
function Footer() {
  return (
    <Box sx={{ paddingTop: '32px', textAlign: 'center' }}>
      <img src={globe} alt="globe" width="70%" />
      <Flex
        style={subMenuStyle}
        color="white"
        bg="#f2f2f2"
        // px={[5,6, 7]}
        py="2"
        alignItems="center"
      >
        <Link py={2} sx={menuItemStyle} variant="nav" href="/">
          Home
        </Link>
        <Link px={4} py={2} sx={menuItemStyle} variant="nav" href="mailto:info@dotpedia.com">
          Contact Us
        </Link>
      </Flex>
      <Flex
        color="white"
        bg="#e6e6e6"
        style={subMenuStyle}
        // px={[5,6, 7]}
        py="2"
        alignItems="center"
      >
        <Text sx={menuItemStyle}>© 2020 Dotpedia.com. All rights reserved.</Text>
      </Flex>
    </Box>
  );
}

export default Footer;
